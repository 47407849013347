<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" name="organizationId" label="影院组织">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 250px;" @change="(id) => getAllCinemaList(id, true)">
							<!-- <a-select-option :value="0">全部</a-select-option> -->
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
						<a-select placeholder="请选择" v-model:value="formState.cinemaId" style="width: 250px;">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="线上会员卡号" name="cardNumber" class="ui-form__item">
						<a-input v-model:value="formState.cardNumber" placeholder="请输入线上会员卡号"></a-input>
					</a-form-item>
					
					<a-form-item label="绑定状态" name="isBind" class="ui-form__item">
						<a-select v-model:value="formState.isBind" style="width: 180px;" placeholder="请选择绑定状态">
							<a-select-option :value="''">全部</a-select-option>
							<a-select-option :value="1">已绑定</a-select-option>
							<a-select-option :value="0">未绑定</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="状态" name="isDisabled" class="ui-form__item">
						<a-select v-model:value="formState.isDisabled" style="width: 180px;" placeholder="请选择状态">
							<a-select-option :value="''">全部</a-select-option>
							<a-select-option :value="1">已禁用</a-select-option>
							<a-select-option :value="0">已启用</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>
				
				<a-row>
					<a-col :span="18">
						<a-button v-permission="['coupon_stored_list_import']" type="primary" @click="onImport">导入</a-button>
						<span v-permission="['coupon_stored_list_export']" style="margin-left: 20px;">
							<ExportReport type="appCardList" :searchData="searchData"></ExportReport>
						</span>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>
			
			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id" :scroll="{ x: 1000 }">
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'time'">
							{{ transDateTime(record.receiveStartTime, 1) }} - {{ transDateTime(record.receiveEndTime, 1) }}
						</template>
						<template v-if="column.key === 'barcode'">
							<span :id="'copyed' + index">{{ record.barcode }}</span>
						</template>
						<template v-if="column.key === 'status'">
							<span>{{ record.isBind === 1 ? '已绑定' : '未绑定' }}</span>
						</template>
						<template v-if="column.key === 'isDisabled'">
							<span>{{ record.isDisabled === 1 ? '已禁用' : '已启用' }}</span>
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div v-permission="['coupon_stored_list_disabled']"
											v-if="record.isDisabled === 0" @click="onDisabled(record)">
											<a-menu-item>
												禁用
											</a-menu-item>
										</div>
										<div v-permission="['coupon_stored_list_open']" v-else
											@click="onDisabled(record)">
											<a-menu-item>
												启用
											</a-menu-item>
										</div>
										<div v-if="record.isBind === 0" v-permission="['coupon_stored_list_bind']"
											 @click="onBind(record)">
											<a-menu-item>
												绑定
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
		
		<a-modal v-model:visible="showImportModal" title="导入线上会员卡" width="750px" @ok="onImportCard">
			<a-spin :spinning="loading">
				<a-form ref="importFormRef" name="importFormRef" scrollToFirstError :model="importModelRef"
					:labelCol="{ span: 4 }" :wrapperCol="{ span: 14 }">
					
					<a-form-item name="organizationId" label="影院组织" :rules="[{ required: true, message: '必选项不允许为空' }]">
						<a-select placeholder="请选择" v-model:value="importModelRef.organizationId" @change="(id) => getAllCinemaList(id, false)">
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item name="cinemaId" label="所属影院" :rules="[{ required: true, message: '必选项不允许为空' }]">
						<a-select placeholder="请选择" v-model:value="importModelRef.cinemaId" @change="getCardPolicyList">
							<a-select-option v-for="item in cinemaList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
										
					<a-form-item label="卡等级" name="cardId" :rules="[{required: true, message: '必选项不允许为空'}]">
					    <a-select style="width: 300px;" v-model:value="importModelRef.cardId" placeholder="请选择">
					        <a-select-option v-for="item in cardPolicyList" :key="item.id" :value="item.id">{{ item.title }}</a-select-option>
					    </a-select>
					    <a-button style="margin-left: 10px;" type="primary" shape="circle" @click="getCardPolicyList">
					        <template #icon>
					            <Icon icon="RedoOutlined"></Icon>
					        </template>
					    </a-button>
					</a-form-item>
					
					<a-form-item label="点击下载">
						<!-- @click="onDownload" -->
						<a href="/dashboard/导入线上会员卡模板.xlsx">Excel模板</a>
					</a-form-item>
		
					<a-form-item label="选择文件">
						<a-upload v-model:file-list="fileList" list-type="picture" action="/admin/ajaxUpload.do"
							accept=".xlsx" :max-count="1" :beforeUpload="beforeUpload">
							<a-button>
								<Icon icon="UploadOutlined"></Icon>
								上传
							</a-button>
						</a-upload>
					</a-form-item>
					
					<p style="color:red;text-align: center;">
						注：请下载模板输入信息，填写完成后保存，然后选择表格上传
					</p>
				</a-form>
			</a-spin>
		</a-modal>
		
		<a-modal title="修改线上会员卡余额" width="600px" v-model:visible="showModal" @cancel="onModifyCancel"
			@ok="onModifyOk">
			<a-spin :spinning="loading">
				<a-form ref="modifyForm" name="modifyForm" :model="modelRef" :labelCol="{ span: 6 }"
					:wrapperCol="{ span: 14 }">
					<a-form-item label="现有余额">
						{{ modifyItem.balance || 0 }} 元
					</a-form-item>
		
					<a-form-item label="调整后余额" name="balance" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-input-number v-model:value="modelRef.balance" :min="0" :precision="2" placeholder="请输入">
						</a-input-number> 元
					</a-form-item>
				</a-form>
			</a-spin>
		</a-modal>

		<a-modal v-model:visible="setVisible" destroyOnClose width="700px" title="绑定会员" @ok="onMemberOk">
			<a-spin :spinning="loading">
				<a-form ref="setMemberForm" name="setMemberForm" :model="memberModelRef" :labelCol="{span: 6}" :wrapperCol="{span: 14}">
					<a-form-item label="所属影院">
						{{ bindItem.cinemaName }}
					</a-form-item>

					<a-form-item label="线上会员卡等级">
						{{ bindItem.cardTitle }}
					</a-form-item>

					<a-form-item label="线上会员卡号">
						{{ bindItem.cardNumber }}
					</a-form-item>

					<a-form-item label="会员手机号" name="phone" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-input v-model:value="memberModelRef.phone" placeholder="请输入会员手机号"/>
					</a-form-item>
				</a-form>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import ExportReport from '@/components/exportReport/exportReport.vue';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	import { getCardPolicyList, getStoredCardList, storedImport, storedPullOnShelves, storedPullOffShelves, storedModifyBalance, bindStored } from '@/service/modules/coupon.js';
	export default {
		components: {
			Icon,
			ExportReport
		},
		data() {
			return {
				loading: false,
				formState: {
					cinemaId: 0,
					isBind: '',
					isDisabled: ''
				},
				searchData: {},
				organizationList: [],
				cinemaAllList: [],
				cinemaList: [],
				visible: false,
				setVisible: false,
				selectedRowKeys: [],
				selectedListData: [],
				bindItem: {},
				memberModelRef: {
					member: ''
				},
				list: [],
				columns: [{
					title: '所属影院',
					dataIndex: 'cinemaName'
				}, 
				// {
				// 	title: '批次号',
				// 	dataIndex: 'batchNo'
				// }, 
				 {
					 title: '卡等级',
					 dataIndex: 'cardTitle'
				 },
				{
					title: '线上会员卡号',
					key: 'cardNumber',
					dataIndex: 'cardNumber'
				}, {
					title: '卡余额',
					dataIndex: 'balance'
				}, {
					title: '绑定状态',
					key: 'status',
					width: 130
				}, {
					title: '状态',
					key: 'isDisabled',
					width: 130
				}, {
					title: '操作',
					key: 'action',
					fixed: 'right',
					width: 150
				},],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				cardPolicyList: [],
				showImportModal: false,
				fileList: [],
				importModelRef:{},
				showModal: false,
				modifyItem: {},
				modelRef: {},
				setVisible: false,
				bindItem: {},
				memberModelRef: {}
			}
		},
		created() {
			this.getOrganizationList();
			// this.onSearch();
		},
		methods: {
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.selectedListData = [];
				this.selectedRowKeys = [];
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
				this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
				this.searchData.isBind = this.searchData.isBind !== '' ? this.searchData.isBind : undefined;
				this.searchData.isDisabled = this.searchData.isDisabled !== '' ? this.searchData.isDisabled : undefined;
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.time = [];
				this.time1 = [];
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getStoredCardList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						type: 3,
						...this.searchData
					});
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId, true);
						}
						this.onSearch();
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId, isSearch) {
				if (isSearch) {
					this.cinemaAllList = [];
					this.formState.cinemaId = 0;
				} else {
					this.cinemaList = [];
					this.importModelRef.cinemaId = undefined;
				}
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					if (isSearch) {
						this.cinemaAllList = ret.data.list;
					} else {
						this.cinemaList = ret.data.list;
					}
				}
			},
			async getCardPolicyList() {
				if (!this.importModelRef.cinemaId) {
					return this.$message.warn('请选择所属影院后重试');
				}
				this.cardLoading = true;
				try {
					let ret = await getCardPolicyList({
						page: 1,
						pageSize: 99999,
						organizationId: this.importModelRef.organizationId,
						cinemaId: this.importModelRef.cinemaId,
						type: 3,
						isDisabled: 0
					});
					this.cardLoading = false;
					if (ret.code === 200) {
						this.cardPolicyList = ret.data.list;
					}
				} catch(e) {
					this.cardLoading = false;
				}
			},
			onDisabled(item) {
				this.$confirm({
					title: '提示',
					content: "确定" + (item.isDisabled === 0 ? '禁用' : '启用') + '此卡吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret;
							if (item.isDisabled === 0) {
								ret = await storedPullOffShelves({
									id: item.id
								})
							} else {
								ret = await storedPullOnShelves({
									id: item.id
								})
							}
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('操作成功');
								this.getData();
							}
						} catch (e) {
							this.loading = false;
						}
					}
				})
			},
			onModifyBalance(record) {
				this.modifyItem = record;
				this.modelRef = {};
				this.showModal = true;
			},
			onModifyCancel() {
				this.$refs.modifyForm.resetFields();
				this.modifyItem = {};
				this.showModal = false;
			},
			onModifyOk() {
				this.$refs.modifyForm.validateFields().then(async () => {
					this.loading = true;
					try {
						let ret = await storedModifyBalance({
							id: this.modifyItem.id,
							balance: this.modelRef.balance
						})
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('余额调整成功');
							this.$refs.modifyForm.resetFields();
							this.showModal = false;
							this.getData();
						}
					} catch (e) {
						this.loading = false;
					}
				})
			},
			onBind(item) {
				this.memberModelRef = {};
				this.bindItem = JSON.parse(JSON.stringify(item));
				this.setVisible = true;
			},
			onMemberOk() {
				//  单一绑定
				this.$refs.setMemberForm.validateFields().then(async () => {
					this.loading = true;
					try {
						let ret = await bindStored({
							id: this.bindItem.id,
							phone: this.memberModelRef.phone
						})
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('绑定成功');
							this.memberModelRef = {};
							this.setVisible = false;
							this.getData();
						}
					} catch(e) {
						this.loading = false;
					}
				})
			},
			getCheckboxProps(record) {
				return { disabled: record.status === 1 }
			},
			onSelectChange(record, selected) {
				if (selected) {
					this.selectedListData.push(JSON.parse(JSON.stringify(record)));
					this.selectedRowKeys.push(record.id);
				} else {
					this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
					this.selectedListData = this.selectedListData.filter(item => {
						return item.id !== record.id;
					});
				}
			},
			onSelectAll(selected) {
				if (selected) {
					this.list.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) === -1) {
							this.selectedListData.push(JSON.parse(JSON.stringify(item)));
							this.selectedRowKeys.push(item.id);
						}
					})
				} else {
					this.list.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) !== -1) {
							this.selectedListData.splice(this.selectedRowKeys.indexOf(item.id), 1);
							this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
						}
					})
				}
			},
			onBatchBind() {
				let canBatchBind = true;
				for (let i = 0; i < this.selectedListData.length;i++) {
					if (this.selectedListData[i].isBind) {
						canBatchBind = false;
						break;
					}
				}
				if (!canBatchBind) {
					return this.$message.warn('已选择的兑换券中含有已绑定的兑换券，无法进行批量绑定操作');
				}
				this.memberModelRef = {};
				this.visible = true;
			},
			onImport() {
				this.fileList = [];
				this.showImportModal = true;
				this.$nextTick(() => {
					this.$refs.importFormRef.resetFields();
				})
			},
			onImportCard() {
				if (this.fileList.length === 0) {
					return this.$message.warn('请上传文件');
				}
				const isLt1M = this.fileList[0].size / 1024 / 1024 < 1;
				if (!isLt1M) {
					this.$message.error('上传文件太大了, 最大只允许1M');
				}
				this.$refs.importFormRef.validateFields().then(async () => {
					let postData = JSON.parse(JSON.stringify(this.importModelRef));
					postData.isDisabled = 0;
					this.loading = true;
					try {
						let ret = await storedImport(postData, this.fileList[0]);
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('导入任务创建成功，请前往【卡券-任务中心-导入任务列表】查看进度详情');
							this.$refs.importFormRef.resetFields();
							this.fileList = [];
							this.showImportModal = false;
							this.getData();
						}
					} catch(e) {
						this.loading = false;
					}
				})
			},
			beforeUpload(file) {
				const isLt1M = file.size / 1024 / 1024 < 1;
				if (!isLt1M) {
					this.$message.error('上传文件太大了, 最大只允许1M');
				}
				return false;
			}
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
